html {
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
}

#main-wrapper {
  height: 100vh;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  overflow-y: visible;
}

.data-table-container{
  height: 100%;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

/* Make skip navigation link visible above the header when focused */
#skip {
  z-index: 9999;
}